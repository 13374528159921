import React from "react";
import Header from "../../components/Header/Header";
import Container from "../../components/Container/Container";
import { TitleParagraph } from "../../components/RenderMappedJSONContent/RenderMappedJSONContent";
import termsContent from "../../Json_Templates/TermsOfServices/TermsOfServices.json";

const TermsOfService = () => {
  return (
    <Container
      backgroundColor="#fff"
      wrapperStyles={{ backgroundColor: "#fff"}}
    >
      <Header isConversationPath={false} />
      {termsContent.TitleParagraphs.map((item, index) => (
        <div key={index}>
          <h4 style={{ marginTop: "50px", padding: "10px" }}>{item.title}</h4>
          {item.paragraphs.map((paragraph, pIndex) => (
            <TitleParagraph
              key={pIndex}
              item={{ title: "", paragraph: paragraph }}
              titleStyle={{ display: "none" }}
              paragraphStyle={{ fontFamily: "poppins", padding: "10px" }}
              titleClass=""
              paragraphClass=""
            />
          ))}
        </div>
      ))}
    </Container>
  );
};

export default TermsOfService;
