import React, { useEffect } from "react";
import Device from "../../utils/responsive.util";

import styles from "./Library.module.scss";

import ArchiveHomeScreen from "../../components/ArchiveHomeScreen/ArchiveHomeScreen.tsx";
import NavigationalTabs from "../../components/NavigationalTabs/NavigationalTabs";

import Header from "../../components/Header/Header";
import Container from "../../components/Container/Container";
import Icon from "../../components/Icon/Icon";
import Footer from "../../components/Footer/Footer";

import libraryJSON from "../../Json_Templates/Library/library.json";
import { useParams } from "react-router-dom";
import * as Events from "../../Types/event-types";
import recordEvent from "../../utils/recordEvent";

const screen = Device();
const containerMargin = screen.isPhone() ? "auto" : "163px";
function Library(props) {
  const showHomeScreen =
    props.location.state && typeof props.location.state.tab === "number";

  const homeScreenColor = showHomeScreen ? "#FFF" : "#faf7dc";
  const footerDeskStopSize = "163px";

  const { tabName } = useParams();

  useEffect(() => {
    recordEvent(
      Events.Categories.library,
      Events.Actions.library,
    );
  }, [])

  return (
    <React.Fragment>
      <Header showGauge={false} noBottomBorder={screen.isPhone()} isConversationPath={false}>
        <Container
          style={{
            backgroundColor: "#FAF7DC",
            containerBackground: homeScreenColor,
            containerMargin: containerMargin,
          }}
          containerStyles={{
            marginBottom: screen.isDesktop() ? footerDeskStopSize : "auto",
          }}
          wrapperStyles={{
            display: "flex",
            marginBottom: "75px",
            flexDirection: "column",
            backgroundColor: homeScreenColor,
          }}
        >
          <div
            className={styles.title}
            onClick={() => props.history.push("/Library")}
          >
            <p>
              <Icon icon="libraryHeader" style={{ marginRight: "5px" }} />
              Library
            </p>
          </div>

          {showHomeScreen ? (
            <NavigationalTabs
              tab={1}
              props={{ ...props }}
              data={libraryJSON}
            />
          ) : (
            <ArchiveHomeScreen {...props} data={libraryJSON} defaultTab={tabName} />
          )}
        </Container>
      </Header>
      <Footer
        hideSearchBar={screen.isPhone()}
        active="library"
        isTransparentBG={!showHomeScreen}
      />
    </React.Fragment>
  );
}

export default Library;
