// useQuestionnaire.js
import { useState } from "react";
import { setContactMode, setContactsMode } from "../services/profile-service";
import { successToast } from "../components/Toastify/Toastify";

import * as Events from "../Types/event-types";
import recordEvent from "../utils/recordEvent";

export const useQuestionnaire = () => {
  const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]);
  const questions = [
    "Have someone call me about advanced care planning.",
    "Have someone call me about palliative care.",
    "Have someone call me about hospice care.",
    "Have someone call me about general questions."
  ];

  const handleOnChange = async (e: any) => {
    const index = questions.indexOf(e.target.name);
    // @ts-ignore
    const updatedSelectedQuestions = selectedQuestions.includes(questions[index]) ? selectedQuestions.filter(question => question !== questions[index]) : [...selectedQuestions, questions[index]];
    setSelectedQuestions(updatedSelectedQuestions);
  };

  const handleSubmit = async () => {
    // @ts-ignore
    if (selectedQuestions.length !== 0) {
      try {
        // @ts-ignore
        const questionsIndexes = selectedQuestions.map(question => questions.indexOf(question).toString())
        const response = await setContactsMode(questionsIndexes);
        if (response.status === 200) {
          successToast("Your request has been submitted.");
          recordEvent(
            Events.Categories.questionnaire,
            Events.Actions.submitQuestionnaire,
            selectedQuestions.join(","),
            selectedQuestions.length
          )
          while(selectedQuestions.length > 0){
            switch(selectedQuestions[0]){
              case "Have someone call me about advanced care planning.":
                recordEvent(
                  Events.Categories.questionnaire,
                  Events.Actions.submitQuestionnaireAdvancedCare,
                )
                break;
              case "Have someone call me about palliative care.":
                recordEvent(
                  Events.Categories.questionnaire,
                  Events.Actions.submitQuestionnairePalliativeCare,
                )
                break;
              case "Have someone call me about hospice care.":
                recordEvent(
                  Events.Categories.questionnaire,
                  Events.Actions.submitQuestionnaireHospiceCare,
                )
                break;
              case "Have someone call me about general questions.":
                recordEvent(
                  Events.Categories.questionnaire,
                  Events.Actions.submitQuestionnaireGeneralQuestions,
                )
                break;
              default:
                break;
            }
            selectedQuestions.shift()
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("No option selected.");
    }
  };

  return { handleOnChange, handleSubmit, selectedQuestions, questions };
};
