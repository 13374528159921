import React, { useState } from "react";
import Container from "../../components/Container/Container";
import Header from "../../components/Header/Header";
import Banner from "../../components/Banner/Banner";
import { Box, Typography } from "@material-ui/core";
import Dropdown from "../../components/DropDown/DropDown";
import InputFieldSet from "../../components/InputFieldSet/InputFieldSet";
import { putProfileOnboarding } from "../../services/profile-service";
import onboardingData from "../../Json_Templates/OnBoarding/OnBoarding.json";
const AccountSetup = ({ history }) => {
  const [age, setAge] = useState("");
  const [caregiverChoiceId, setCaregiverChoiceId] = useState("");
  const [caregiverChoice, setCaregiverChoice] = useState("");

  const handleNext = async () => {

    // Match the inputs with their respective question objects from the JSON
    const ageQuestionObj = onboardingData.AccountSetup.find(
      (q) =>
        q.question ===
        "Now Tell us a little about yourself so that we can better customize the information you need"
    );
    const caregiverQuestionObj = onboardingData.AccountSetup.find(
      (q) => q.question === "Who is your loved ones primary unpaid caregiver?"
    );

    const choices = [
      {
        choiceId: ageQuestionObj.choiceId,
        question: ageQuestionObj.question,
        value: age,
        category: "onboarding",
      },
      {
        choiceId: caregiverChoiceId,
        question: caregiverQuestionObj.question,
        value: caregiverChoice,
        category: "onboarding",
      },
    ];

    try {
      await putProfileOnboarding(
        history.location.state.userData.profileId,
        choices
      );
      history.push("/OnBoarding/Step3");
    } catch (error) {
      console.error("Error submitting profile choices", error);
    }
  };

  const handleCaregiverChange = (event) => {
    const selectedChoice = onboardingData.AccountSetup[1].items.find(
      (item) => item.value === event.target.value
    );

    if (selectedChoice) setCaregiverChoiceId(selectedChoice.choiceId);
    if (selectedChoice) setCaregiverChoice(selectedChoice.value);
  };

  return (
    <Container
      style={{ containerPadding: "100px" }}
      containerStyles={{
        width: "100vw",
        height: "100vh",
        position: "relative",
        minHeight: 500,
        top: null,
      }}
      wrapperStyles={{ backgroundColor: "#fff" }}
      isButtonShown
      buttonProps={{
        position: "absolute",
        bottom: "0",
        left: "0",
        right: "0",
      }}
      buttonTitle="NEXT"
      buttonOnClick={handleNext}
    >
      <Header showGauge={false} isConversationPath={false} />
      <Box style={{ marginTop: "40px", height: "auto" }}>
        <Banner
          iconName={"account-setup"}
          history={history}
          color="#FAF7DC"
          textColor="#146E6C"
          showArrow
        />
        <Box
          style={{
            marginLeft: "27px",
            marginRight: "35px",
          }}
        >
          <Typography
            style={{
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 600,
              fontSize: "17px",
              lineHeight: "24px",
              letterSpacing: "0px",
              textAlign: "left",
              color: "#146E6C",
              marginTop: "40px",
            }}
          >
            About You
          </Typography>
          <Typography
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "normal",
              fontSize: "14px",
              lineHeight: "20px",
              letterSpacing: "0px",
              textAlign: "left",
              color: "#000000",
            }}
          >
            {onboardingData["AccountSetup"][0].question}
          </Typography>
          <form style={{ marginTop: "16px" }}>
            <InputFieldSet
              name="age"
              title="Your Age"
              type="number"
              fullWidth
              style={{ margin: 0 }}
              value={age}
              onChange={(e) => {
                e.target.value = e.target.value < 0 ? "" : e.target.value
                setAge(e.target.value)
              }}
              onKeyPress={(event) => {
                if (event.key === "+" || event.key === "-") {
                  event.preventDefault();
                }
              }}
            />
            <Dropdown
              label="Who is your loved one’s primary unpaid caregiver? "
              value={caregiverChoice}
              showPopUpLabel
              labelStyles={{
                font: "14px/19px Poppins, sans-serif",
                fontWeight: 600,
                letterSpacing: "0px",
                color: "#1D918B",
                marginTop: "35px",
              }}
              items={onboardingData.AccountSetup[1].items.map(
                (item) => item.value
              )}
              onChange={handleCaregiverChange}
            />
          </form>
        </Box>
      </Box>
    </Container>
  );
};

export default AccountSetup;
