import React from "react";
import Container from "../../components/Container/Container";
import Header from "../../components/Header/Header";
import { Box, Typography } from "@material-ui/core";
import Banner from "../../components/Banner/Banner";
import InputFieldSet from "../../components/InputFieldSet/InputFieldSet";
import DropDown from "../../components/DropDown/DropDown";
import Slider from "../../components/Slider/Slider";
import { createMarks } from "../../utils/slider.util";

const AccountSetupDetails = () => {
  const legend = ["Yes", "No ", "Not sure"];

  return (
    <Container
      style={{ containerPadding: "50px", height: "100vh" }}
      containerStyles={{
        height: "100vh",
        width: "100vw",
        position: "relative",
        top: null,
      }}
      wrapperStyles={{ backgroundColor: "#fff" }}
      isButtonShown
      buttonProps={{
        position: "absolute",
        bottom: "0",
        left: "0",
        right: "0",
      }}
      buttonTitle="COMPLETE"
    >
      <Header showGauge={false} isConversationPath={false} />
      <Box style={{ marginTop: "40px", height: "auto", marginBottom: "40px" }}>
        <Banner iconName={"account-setup"} color="#fff" />
        <Box
          style={{
            marginLeft: "27px",
            marginRight: "35px",
          }}
        >
          <Typography
            style={{
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 600,
              fontSize: "17px",
              lineHeight: "24px",
              letterSpacing: "0px",
              textAlign: "left",
              color: "#146E6C",
              marginTop: "40px",
            }}
          >
            About Your Loved One
          </Typography>
          <form style={{ marginTop: "16px" }}>
            <InputFieldSet
              name="age"
              title="Your Loved Ones Age"
              type="number"
              fullWidth
              style={{ margin: 0 }}
              onChange={e => 
                e.target.value = e.target.value < 0 ? "" : e.target.value
              }
              onKeyPress={(event) => {
                if (event.key === "+" || event.key === "-") {
                  event.preventDefault();
                }
              }}
            />
            <Slider
              marks={createMarks(legend)}
              step={null}
              sliderColor={"#3DADA2"}
              title={
                "Has anyone recommended that you look into a palliative care, palliative care, or hospice consult?"
              }
              onChangeCommitted={(event, value) => {}}
              titleStyles={{
                textAlign: "left",
                fontFamily: "Poppins, sans-serif",
                fontSize: "14px",
                lineHeight: "19px",
                color: "#1D918B",
                fontWeight: 600,
              }}
              sliderStyle={{
                marginTop: "35px",
              }}
            />
            <DropDown
              label="Where are they currently livings?"
              showPopUpLabel
              labelStyles={{
                font: "14px/19px Poppins, sans-serif",
                fontWeight: 600,
                letterSpacing: "0px",
                color: "#1D918B",
                marginTop: "35px",
              }}
              items={[
                "Me",
                "My Brother",
                "My sister",
                "Their spouse",
                "Their Friend",
              ]}
            />
            <Slider
              marks={createMarks(legend)}
              step={null}
              sliderColor={"#3DADA2"}
              title={
                "Has anyone recommended that you look into a palliative care, palliative care, or hospice consult?"
              }
              onChangeCommitted={(event, value) => {}}
              titleStyles={{
                textAlign: "left",
                fontFamily: "Poppins, sans-serif",
                fontSize: "14px",
                lineHeight: "19px",
                color: "#1D918B",
                fontWeight: 600,
              }}
              sliderStyle={{
                marginTop: "35px",
              }}
            />
          </form>
        </Box>
      </Box>
    </Container>
  );
};

export default AccountSetupDetails;
