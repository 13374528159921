import React from "react";
import styles from "./OnBoardingStart.module.scss";
import Device from "../../utils/responsive.util";
import { getLocalStorageCId } from "../../utils/cId";

import IntroTitle from "../../components/IntroTitle/IntroTitle";
import ProvidedByLogo from "../../components/ProvidedByLogo/ProvidedByLogo";
import ProceedButton from "../../components/ProceedButton/ProceedButton";

import * as Events from "../../Types/event-types";
import recordEvent from "../../utils/recordEvent";

function OnBoarding(props) {
  const device = Device();
  device.bodyAccount(true);
  const [screenWidth, setScreenWidth] = React.useState(device.getW());
  const [isPhone, loadPhoneBehavior] = React.useState();
  window.onresize = () => {
    setScreenWidth(device.getW());
  };
  React.useEffect(() => {
    device.isPhone(screenWidth) && loadPhoneBehavior(true);
  }, [screenWidth]);

  const renderOnBoardingStart = () => {
    return (
      <div className={styles.App}>
        <IntroTitle showHealthCareIcon logo={getLocalStorageCId()} />
        <div className={styles.userInstructions}>
          <ProceedButton
            title={"Start Here"}
            buttonStyles={{
              border: "none",
              borderRadius: "5px",
              width: "193px",
            }}
            onClick={() => props.history.push("/OnBoarding/Step1")}
          />
        </div>
        <div className={styles.returningUserWrapper}>
          <span className={styles.returningUser}>Returning User? </span>
          <span
            className={styles.login}
            onClick={() => {
              recordEvent(
                Events.Categories.onboarding,
                Events.Actions.login,
                Events.Labels.desktop
              );
              return props.history.push("/Account/SignIn");
            }}
          >
            Log In Here
          </span>
        </div>
        <ProvidedByLogo />
      </div>
    );
  };
  const renderAccessMessage = () => {
    return (
      <div className={styles.App}>
        <IntroTitle showHealthCareIcon logo={getLocalStorageCId()} />
        <p className={styles.accessMessage}>
          Check with your plan sponsor to get an access token.
        </p>
      </div>
    );
  };
  return renderOnBoardingStart();
}

export default OnBoarding;
