import React, { useContext, useState, useEffect } from "react";
import Psi from "../../assets/psi.png";
import { UserDataContext } from "../../Context/Auth-Provider";
import { getLocalStorageCId } from "../../utils/cId";

interface LogoProps {
  height?: number;
  className?: string;
  onClick?: () => void;
  isConversation?: boolean;
}

const Logo: React.FC<LogoProps> = ({
  height,
  className,
  onClick,
  isConversation = true,
}) => {
  const [src, setSrc] = useState("")
  const context = useContext(UserDataContext);
  let filename = context!.isLoggedIn && context!.profile.affiliation ? `${context!.profile.affiliation}.svg` : getLocalStorageCId();
  useEffect(() => {
    import(`../../assets/${filename}`).then(cIdLogo => setSrc(cIdLogo.default))
  }, [context])

  return (
    <img
      src={isConversation ? src : Psi}
      onClick={onClick && onClick}
      className={className && className}
      height={height}
      alt={"logo"}
      style={{ cursor: "pointer" }}
    />
  );
};

export default Logo;
