import React, { useState } from "react";
import {
  createProfile,
  updateProfile,
  getProfile,
  putProfile,
} from "../../services/profile-service";

import styles from "../OnBoarding/OnBoardingStepTwo.module.scss";

import Container from "../../components/Container/Container";
import Dropdown from "../../components/DropDown/DropDown";

import { IoMdAdd } from "react-icons/io";

import * as Events from "../../Types/event-types";
import recordEvent from "../../utils/recordEvent";
import Header from "../../components/Header/Header";
import Banner from "../../components/Banner/Banner";
import { Box, Typography } from "@material-ui/core";
import CheckboxesGroup from "../../components/Checkbox/Checkbox";

const checkBoxLabelStyles = {
  fontSize: 14,
  fontFamily: "Poppins",
  fontWeight: 300,
  lineHeight: "19px",
  textAlign: "left",
  letterSpacing: "0px",
  color: "#4A4A4A",
  opacity: 1,
};



const items = [
  {
    value: "Certified Homecare | Eddy VNA",
    choiceId: '103fd8ae-5a75-45ee-b433-570060a94ffb',
    category: "onboarding"
  },
  {
    value: "PACE program",
    choiceId: '203fd8ae-5a75-45ee-b433-570060a94ffb',
    category: "onboarding"
  },
  {
    value: "Home Based Primary Care",
    choiceId: '303fd8ae-5a75-45ee-b433-570060a94ffb',
    category: "onboarding"
  },
  {
    value: "Independent Living",
    choiceId: '403fd8ae-5a75-45ee-b433-570060a94ffb',
    category: "onboarding"
  },
  {
    value: "Assisted Living",
    choiceId: '503fd8ae-5a75-45ee-b433-570060a94ffb',
    category: "onboarding"
  },
  {
    value: "Memory Care",
    choiceId: '603fd8ae-5a75-45ee-b433-570060a94ffb',
    category: "onboarding"
  },
  {
    value: "Medical Practices",
    choiceId: '703fd8ae-5a75-45ee-b433-570060a94ffb',
    category: "onboarding"
  },
  {
    value: "Hospital",
    choiceId: '803fd8ae-5a75-45ee-b433-570060a94ffb',
    category: "onboarding"
  },
  {
    value: "Other",
    choiceId: '903fd8ae-5a75-45ee-b433-570060a94ffb',
    category: "onboarding"
  },
];



const OnBoardingStepTwo = ({ history }) => {
  const choicePerson = ["Mother", "Father", "Mother-in-law", "Father-in-law"];

  const [selection, setSelection] = useState(false);
  const [userSelection, setUserSelection] = useState([]);

  const [errorMessage, setErrorMessage] = useState();
  const [renderDropdowns, setRenderDropdowns] = useState([choicePerson]);
  const [checkboxCount, setCheckboxCount] = useState(0);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const isButtonEnabled = selection && checkboxCount > 0;

  const handleCheckboxChange = (e) => {
    const itemName = e.target.name;
    const item = items.find(i => i.value === itemName);

    if (!item) return;

    let updatedCheckboxes = [...selectedCheckboxes];

    if (e.target.checked) {
      updatedCheckboxes.push({ choiceId: item.choiceId, value: item.value, category: item.category });
    } else {
      updatedCheckboxes = updatedCheckboxes.filter(selectedItem => selectedItem.choiceId !== item.choiceId);
    }

    const mappedCheckboxes = updatedCheckboxes.map(item => {
      return {
        choiceId: item.choiceId,
        value: item.value ? 1 : 0,
        category: item.category
      }
    });
    setSelectedItems(mappedCheckboxes);
    setSelectedCheckboxes(updatedCheckboxes);
    setCheckboxCount(updatedCheckboxes.length);
  };






  let userId;
  try {
    userId = history.location.state.userId;
  } catch (error) { }

  const handleUserSelect = (e, i, dropdownIndex) => {
    const values = {
      person: e.target.value,
      value: i.props.id,
    };

    userSelection[dropdownIndex] = values;
    setUserSelection([...userSelection]);
    setSelection(renderDropdowns.length === userSelection.length);
  };

  const setProfile = async () => {

    const fullName = `${history.location.state.firstName}  ${history.location.state.lastName}`;

    let response;
    const lovedOne = userSelection[0].value;
    try {
      const doesUserExist = await getProfile();
      if (doesUserExist.data.length === 0) {
        response = await createProfile(lovedOne, fullName);
        await putProfile(response.data.profileId, selectedItems);

        if (userSelection[0] && response.status === 201) {
          recordEvent(
            Events.Categories.onboarding,
            Events.Actions.new,
            Events.Labels.userProfile
          );
          history.push("/Account/AccountSetup", {
            userSelection,
            person: lovedOne,
            userId,
            userData: response.data,
          });
        }
      } else {
        const userProfileId = doesUserExist.data[0].profileId;
        const newProfile = {
          relationship: lovedOne,
          name: fullName,
          hasCompletedConversation: 0,
          choices: selectedCheckboxes,

        };

        const update = await updateProfile(userProfileId, newProfile);
        if (userSelection[0] && update.status === 204) {
          recordEvent(
            Events.Categories.onboarding,
            Events.Actions.update,
            Events.Labels.userProfile
          );
          history.push("Account/AccountSetup", {
            userSelection,
            person: lovedOne,
            userId,
            userData: response.data,
          });
        }
      }
    } catch (error) {
      setErrorMessage(errorMessage);
    }
  };

  return (
    <Container
      style={{ containerPadding: "50px", }}
      backgroundColor="#fff"
      containerStyles={{
        width: "100vw",
        height: "100vh",
        position: "relative",
        top: null,
      }}
      wrapperStyles={{ backgroundColor: "#fff" }}
      isButtonShown
      buttonProps={{
        position: "absolute",
        bottom: "0",
        left: "0",
        right: "0",
      }}
      buttonOnClick={setProfile}
      buttonTitle="NEXT"
      isButtonDisabled={!isButtonEnabled}
    >
      <div>
        <Header showGauge={false} isConversationPath={false} hideHamburger />
        <Box style={{ marginTop: "40px", height: "auto" }}>
          <Banner
            history={history}
            color="#FAF7DC"
            textColor="#146E6C"
            showArrow
          />
          <div className={styles.dropdownContainer}>

            {renderDropdowns.map((dropdownItems, idx) => {
              return (
                <Dropdown
                  items={dropdownItems}
                  height={30}
                  onChange={(e, i) => {
                    handleUserSelect(e, i, idx);
                  }}
                  defaultValue={userSelection[idx] && userSelection[idx].person}
                  label={"Who are you here for?"}
                  labelStyles={{
                    font: "14px/19px Poppins, sans-serif",
                    fontWeight: 600,
                    letterSpacing: "0px",
                    color: "#1D918B",
                    marginTop: "35px",
                  }}
                  key={`dropdown-${idx}`}
                  width={200}
                />
              );
            })}
            <Typography
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "0px",
                textAlign: "left",
                color: "#1D918B",
                marginTop: "35px",
                marginBottom: "10px",
              }}
            >
              What services is your loved one currently receiving from St.
              Peter’s Health Partners?
            </Typography>
            <CheckboxesGroup
              items={items.map(item => item.value)}
              checkBoxMargin="5px"
              labelStyles={checkBoxLabelStyles}
              handleOnChange={handleCheckboxChange}
              indexesToCheck={selectedCheckboxes.map(item => item.value)}

            />
          </div>
          {renderDropdowns.length === 100 && (
            <IoMdAdd
              size={25}
              style={{
                color: "green",
                position: "relative",
                left: "110px",
                bottom: "75px",
              }}
              onClick={() =>
                setRenderDropdowns([...renderDropdowns, choicePerson])
              }
            />
          )}


        </Box>
      </div>
    </Container >
  );
};

export default OnBoardingStepTwo;
