import React, { useState, useEffect } from "react";
import Button from "../../components/Button/Button";
import Form from "react-bootstrap/Form";
import { FormControlProps } from "react-bootstrap";
import { loginUser } from "../../services/auth-service";
import { getProfile } from "../../services/profile-service";
import styles from "./SignIn.module.scss";
import InputFieldSet from "../../components/InputFieldSet/InputFieldSet";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import IntroTitle from "../../components/IntroTitle/IntroTitle";
import { Link } from "react-router-dom";
import onBoardingJSON from "../../Json_Templates/OnBoarding/OnBoarding.json";
import { getRandomUUID } from "../../utils/crypt";
import ProvidedByLogo from "../../components/ProvidedByLogo/ProvidedByLogo";
import { History } from "history";
import Device from "../../utils/responsive.util";
import ReactGA from 'react-ga4';
import { getLocalStorageCId } from "../../utils/cId";

import * as Events from "../../Types/event-types";
import recordEvent from "../../utils/recordEvent";

interface SignInProps {
  history: HistoryWithVariable;
}

interface HistoryWithVariable extends History {
  cId: string;
  urlKey: string;
}

interface FormInterface extends FormControlProps {
  checkValidity: () => boolean;
}

declare global {
  interface Window {
      amplitude:any;
  }
}

const SignIn: React.FC<SignInProps> = ({ history }) => {

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isNotValidated, setValidation] = useState(false);
  const [hasUserFailedAuthentication, setAuthentication] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState<boolean>();

  const [errorMessage, setErrorMessage] = useState<string>();

  if(localStorage.getItem("cId") == null){
    if(history.urlKey != "")
      localStorage.setItem("cId", history.cId);
  }

  const handleSubmit = (event: React.FormEvent<FormInterface>) => {
    event.stopPropagation();
    event.preventDefault();
    if (username.length === 0 || password.length === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
    const form = event.currentTarget;
    if (form.checkValidity()) {
      handleUserLogin();
    } else {
      setValidation(true);
      setLoading(false);
    }
  };
  const renderForm = () => {
    return (
      <div className={styles.introTitleContainer}>
        <Form
          noValidate
          validated={isNotValidated}
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className={styles.loginInputs}>
            <InputFieldSet
              title="Email Address"
              type="email"
              autoCapitalize="off"
              placeholder="Enter email address"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setUsername(e.target.value);
                hasUserFailedAuthentication && setAuthentication(false);
                setIsEmpty(false);
              }}
              required
              autoComplete="on"
              defaultValue="Error"
              width={300}
            />

            <Form.Group controlId="formBasicPassword">
              <InputFieldSet
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPassword(e.target.value);
                  hasUserFailedAuthentication && setAuthentication(false);
                  setIsEmpty(false);
                }}
                InputProps={{
                  endAdornment: (
                    // @ts-ignore
                    <InputAdornment
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <VisibilityIcon
                        style={{ color: "#9CBF85", cursor: "pointer" }}
                      />
                    </InputAdornment>
                  ),
                }}
                required
                title={"Password"}
                width={300}
              />
              {hasUserFailedAuthentication && (
                <Form.Text className={styles.inValid}>{errorMessage}</Form.Text>
              )}
              {isEmpty && (
                <Form.Text className={styles.inValid}>
                  Username or password cannot be empty.
                </Form.Text>
              )}
            </Form.Group>
          </div>
          <div className={styles.buttonContainer}>
            <Button
              title={isLoading ? "Loading...." : "Log In"}
              isDisabled={isLoading}
              type="submit"
            />
          </div>
        </Form>
      </div>
    );
  };
  const handleUserLogin = async () => {
    setLoading(true);
    try {
      const response = await loginUser(username, password);

      const profileResponse = await getProfile();
      const userId = profileResponse.data[0].userId;
      if (
        response.status === 200 &&
        (profileResponse.data.length === 0 ||
          profileResponse.data[0].choices === null)
      ) {
        return history.push("/HomeScreen", response.data);
      }

      let onBoardingQuestions = 0;

      Object.values(onBoardingJSON).forEach((question) => {
        onBoardingQuestions += question.length;
      });
      // Subtract by -1 from onBoardingQuestions because one Question does not get added to the array on the server.
      if (response.status === 200 && profileResponse.status === 200) {
        const uuid = await getRandomUUID();
        const cId = response.data.customerId
          ? response.data.customerId.toLowerCase()
          : null;
        recordEvent(Events.Categories.signIn, Events.Actions.login, cId);
        ReactGA.gtag('set', 'user_id', userId);
        window.amplitude.setUserId(userId)
        setTimeout(() => {
          setLoading(false);
          history.push("/HomeScreen");
        });
      } else {
      }
    } catch (error) {
      const message =
        error && error.data && error.data.error_description
          ? error.data.error_description
          : "Please contact technical Support.";
      setErrorMessage(message);
      setAuthentication(true);
      setLoading(false);
    }
  };

  const device = Device();
  const [screenWidth, setScreenWidth] = useState(device.getW());
  window.onresize = () => {
    setScreenWidth(device.getW());
  };
  useEffect(() => {
    device.bodyAccount(screenWidth);
  }, [screenWidth]);

  return (
    <div className={styles.App}>
      <IntroTitle logo={getLocalStorageCId()} />
      <>
        {renderForm()}

        <div className={styles.footer}>
          <div>
            <span className={styles.firstTimeText}>New User?</span>
          </div>
          <Link to={"/OnBoarding"}>
            <span className={styles.link}>Start Here</span>
          </Link>
        </div>

        <div className={styles.footer} style={{ marginBottom: "30px" }}>
          <div>
            <span className={styles.firstTimeText}>Forgot Password?</span>
          </div>
          <Link to={"/resetpassword"}>
            <span className={styles.link}>Password Reset</span>
          </Link>
        </div>
        <ProvidedByLogo />
      </>
    </div>
  );
};

export default SignIn;
