import React, { useEffect } from "react";
import Container from "../../components/Container/Container";
import Header from "../../components/Header/Header";
import ConversationsJson from "../../Json_Templates/Conversations/ConversationsWithLovedOne/conversations-step10.json";
import { Typography, Box } from "@material-ui/core";

import { useStyles } from "./styles/conversation-styles";
import * as Events from "../../Types/event-types";
import recordEvent from "../../utils/recordEvent";

import Button from "../../components/Button/Button";

export default function ConversationsStep10({ history }: any) {
  const classes = useStyles();

  useEffect(() => {
    recordEvent(
      Events.Categories.conversations,
      Events.Actions.conversationsStepTen,
      Events.Labels.stepTen
    );
  }, [])

  const onNext = () => {
    history.push("/Conversations/Step4")
  }

  return (
    <div className={classes.root} style={{ height: "130vh" }}>
      {/* @ts-ignore */}
      <Header showProgressBar progressBarStatus={95}>
        <Box className={classes.responsiveWrapper}>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: "9px",
              lineHeight: "20px",
              letterSpacing: "-0.03px",
              textTransform: "uppercase",
              opacity: 1,
              color: "#000000",
              textAlign: "left",
              marginTop: "20px",
            }}
          >
            {ConversationsJson.title}
          </Typography>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: "13px",
              lineHeight: "21px",
              letterSpacing: "-0.07px",
              color: "#188781",
              textAlign: "left",
              textTransform: "uppercase",
              opacity: 1,
              marginBottom: "10px",
            }}
          >
            {ConversationsJson.content.title}
          </Typography>
          <Container
            style={{ width: "100% !important" }}
            backgroundColor="#FAF7DC"
            wrapperStyles={{
              display: "flex",
              alignItems: "center",
              paddingTop: "0px",
              width: "100%",
              position: "static",
            }}
            containerStyles={{
              width: "100%",
              position: "static",
            }}
            isFixed={false}
          >
            {ConversationsJson.content.conversations.map((conversation) => {
              return (
                <Box
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  p={0}
                  height="100%"
                  margin={1}
                >
                  {
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "13px",
                        lineHeight: "17px",
                        letterSpacing: "0px",
                        textAlign: "left",
                        color: "#4A4A4A",
                        opacity: 1,
                      }}
                      gutterBottom
                    >
                      <span>{conversation}</span>
                    </Typography>
                  }
                </Box>
              );
            })}
          </Container>

          <Box style={{ marginTop: "20px" }}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box style={{ marginTop: "20px" }}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Button
                    title="Next"
                    type="button"
                    onClick={onNext}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Header>
    </div>
  );
}
