import React, { Fragment, useState } from "react";
import styles from "../../components/IntroTitle/IntroTitle.module.scss";

interface IntroTitleProps {
  isLogoHidden?: Boolean;
  showDefault?: boolean;
  title?: string;
  logo: any;
}

const IntroTitle: React.FC<IntroTitleProps> = ({
  isLogoHidden,
  showDefault = true,
  title,
  logo,
}) => {
  const [src, setSrc] = useState("");
  import(`../../../src/assets/${logo}`).then((image) => setSrc(image.default));

  return (
    <Fragment>
      {!isLogoHidden && (
        <Fragment>
          <img
            //@ts-ignore
            src={src}
            width={logo === "psi.svg" ? 230 : 291}
            height={106}
            className={styles.logo}
            alt={logo}
          />
        </Fragment>
      )}
      {showDefault && (
        <span className={styles.title}>
          Supportive Care for <br></br>
          Your Loved One
        </span>
      )}
    </Fragment>
  );
};

export default IntroTitle;
