import { Box, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import Button from "../../../components/Button/Button";
import Container from "../../../components/Container/Container";
import ConversationTitle from "../../../components/ConversationTitle/ConversationTitle";

import Header from "../../../components/Header/Header";

import Verbiage from "../../../Json_Templates/Conversations/Onboarding/Onboarding-step4.json";
import * as Events from "../../../Types/event-types";
import recordEvent from "../../../utils/recordEvent";

function ConversationOnboarding({ history }: { history: any }) {
  const useStyles = makeStyles({
    conversationText: {
      fontFamily: "Poppins",
      fontWeight: 600,
      textAlign: "left",
      fontSize: 15,
      letterSpacing: 0.09,
      marginTop: 32,
      textTransform: "uppercase",
    },
    header: {
      fontFamily: "Poppins",
      fontSize: 15,
      color: "#146E6C",
      marginTop: 16,
      marginBottom: 9,
      fontWeight: 600,
    },
    paragraph: {
      font: "normal normal 300 13px/20px Poppins",
      letterSpacing: "0px",
      color: "#000000",
      opacity: 1,
      textAlign: "left",
    },
    list: {
      fontFamily: "Poppins",
      font: "normal normal 300 13px/20px Poppins",
      fontSize: "13px",
      lineHeight: "19px",
      letterSpacing: "-0.13px",
      color: "#000000",
      opacity: 1,
      textAlign: "left",
      "& li::marker": {
        color: "#188781",
      },
    },
  });
  const classes = useStyles();

  useEffect(() => {
    recordEvent(
      Events.Categories.conversations,
      Events.Actions.conversationsOnboardingStepFour,
      Events.Labels.stepFour
    );
  }, [])

  const onNext = () => {
    history.push("/Conversations/Step1")
  }

  return (
    //@ts-ignore
    <Container style={{ containerBackground: "#faf7dc" }} isFixed={false}>
      <Header showProgressBar progressBarStatus={40} />
      <Box p={1} height="100%" marginTop={10} marginLeft={1} marginRight={1}>
        <ConversationTitle title={Verbiage.title} history={history}/>
        <Typography variant="h6" className={classes.header}>
          {Verbiage.content.description}
        </Typography>

        {Verbiage.content.conversations.map((conversation) => {
          return (
            <Typography variant="h6" className={classes.paragraph}>
              <p>{conversation}</p>
            </Typography>
          );
        })}
        {Verbiage.content.list.map((list) => {
          return (
            <Typography variant="h6" className={classes.list}>
              <li>{list}</li>
            </Typography>
          );
        })}
        {Verbiage.content.conversationPartTwo.map((conversation) => {
          return (
            <Box marginTop={1}>
              <Typography variant="h6" className={classes.paragraph}>
                <p>{conversation}</p>
              </Typography>
            </Box>
          );
        })}

        <Box
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          display={"flex"}
        >
          <Button
            title={"Next"}
            type="button"
            onClick={onNext}
            buttonStyles={{ zIndex: 1 }}
          />
        </Box>
      </Box>
    </Container>
  );
}

export default ConversationOnboarding;
