import React from "react";
import ReactDOM from "react-dom";

import ReactGA from "react-ga4";

import { Routes } from "./routes/routes";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import ScrollToTop from "./utils/ScrollToTop";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "./custom.scss";
import "./index.scss";
import "./animations.scss";

import config from "./config";
const { GOOGLE_ANALYTICS_TRACKING_ID } = config;

const history = createBrowserHistory();
const parseUrl = new URLSearchParams(history.location.search);
const urlId = parseUrl.get("cid") || "";
const filename = urlId.toLowerCase();
history.urlKey = filename;
history.cId = `${filename}.svg`;

ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);

history.listen((location) => {
  ReactGA.set({ page: location.pathname });
});

ReactDOM.render(
  <Router history={history}>
    <ScrollToTop />
    <Routes />
    <ToastContainer position="top-center" autoClose={3000} hideProgressBar closeOnClick pauseOnFocusLoss draggable pauseOnHover />
  </Router>,
  document.getElementById("root")
);
