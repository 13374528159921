import { Library } from "../components/JsonContentHelpers/JsonContentHelpers";

export const Categories = {
  onboarding: "onboarding",
  createAccount: "create account",
  signIn: "sign in",
  homescreen: "homescreen",
  homecare: "homecare",
  healthcare: "healthcare",
  insurance: "insurance",
  wellness: "wellness",
  plans: "plans",
  library: "library",
  header: "header",
  questionnaire: "questionnare",
  conversations: "conversations",
  chat: "chat"
};

export const Actions = {
  login: "login",
  logout: "logout",
  launch: "launch",
  newUser: "new user",
  save: "save",
  update: "update",
  add: "add",
  edit: "edit",
  delete: "delete",
  finish: "finish",
  submitQuestionnaire: "Submit Questionnaire",
  submitQuestionnaireAdvancedCare: "Submit Questionnaire Advanced Care Planning",
  submitQuestionnairePalliativeCare: "Submit Questionnaire Palliative Care",
  submitQuestionnaireHospiceCare: "Submit Questionnaire Hospice Care",
  submitQuestionnaireGeneralQuestions: "Submit Questionnaire General Questions",
  submitDiagnosis: "submit Diagnosis",
  onboardingStepFour: "/Onboarding step 4",
  conversationsOnboardingStepFour: "/Conversation onboarding step 4",
  conversationsStepTen: "/Conversation step 10",
  library: "/Library",
  chat: "/Chat"
};

export const Labels = {
  stepOne: "step 1",
  stepTwo: "step 2",
  stepThree: "step 3",
  stepFour: "step 4",
  stepFive: "step 5",
  stepTen: "step 10",
  mobile: "mobile",
  desktop: "desktop",
  userInfo: "user information",
  lovedOneInfo: "loved one information",
};
