import React, { useState, useEffect } from "react";

import Header from "../../components/Header/Header";
import Plank from "../../components/Plank/Plank";
import Ellipse from "../../components/Ellipse/Ellipse";
import Footer from "../../components/Footer/Footer";
import Container from "../../components/Container/Container";
import { HomecareStepOne } from "../../Json_Templates/Homecare/Homecare.json";
import { InsuranceStepOne } from "../../Json_Templates/Insurance/Insurance.json";
import HomeScreenJSON from "../../Json_Templates/HomeScreen";
import HomeCareHomeScreen from "../../Json_Templates/HomeCareHomeScreen.json";
import useIsIOS from "../../utils/useIsIOS.ts";
import Device from "../../utils/responsive.util";
import HomeScreenScreenConversations from "../../Json_Templates/HomeScreenConversations.json";

function getCompletedPlanks(userProfile) {
  if (userProfile && userProfile.length > 0) {
    const finishedPlanks = [];
    const isHomecareComplete = [];
    const isInsuranceComplete = [];
    let isMyWellnessComplete = false;

    userProfile.forEach((choice) => {
      const homecare = HomecareStepOne.choices.find(
        (local) => local.choiceId === choice.choiceId
      );
      homecare && isHomecareComplete.push(homecare);

      const insurance = InsuranceStepOne.choices.find(
        (local) => local.choiceId === choice.choiceId
      );
      insurance && isInsuranceComplete.push(insurance);

      const myWellness = userProfile
        .filter((choice) => choice.category.includes("wellness"))
        .filter((wellness) => wellness.value === 1);

      myWellness.length > 0
        ? (isMyWellnessComplete = true)
        : (isMyWellnessComplete = false);
    });
    const homecare =
      isHomecareComplete.length === HomecareStepOne.choices.length
        ? true
        : false;
    const insurance =
      isInsuranceComplete.length === InsuranceStepOne.choices.length
        ? true
        : false;

    const healthCareChoices = userProfile.filter(
      (savedData, index) => savedData.category === "healthcare"
    );

    healthCareChoices.length >= 1 && finishedPlanks.push("healthcare");
    homecare && finishedPlanks.push("homecare");
    insurance && finishedPlanks.push("insurance");

    isMyWellnessComplete
      ? finishedPlanks.push("myWellness")
      : finishedPlanks.splice(finishedPlanks.indexOf("myWellness"), 0);

    return finishedPlanks;
  }
}

function HomeScreen(props) {
  const isConversationsCompletedObj = props.userData.profileChoices
    ? props.userData.profileChoices.filter(item => item.category === "isConversationsCompleted")
    : null;

  const { prompt } = useIsIOS();
  const [userProfile, loadProfile] = useState();
  const url = window.location.href;
  const isHomecareHomeScreen = url.includes("homecareHomeScreen");
  const isHomeScreenConversations = url.includes("Conversations");

  const isPlankFinished = getCompletedPlanks(userProfile);


  const [homecareData, setHomeData] = useState(HomeScreenJSON);

  const device = Device();
  const [screenWidth, setScreenWidth] = useState(device.getW());
  window.onresize = () => {
    setScreenWidth(device.getW());
  };
  useEffect(() => {
    device.bodyPhone(screenWidth);
  }, [screenWidth]);

  useEffect(() => {
    if (!props.userData.isLoading) loadProfile(props.userData.profileChoices);
  }, [props.userData.isLoading]);

  useEffect(() => {
    if (isHomecareHomeScreen) {
      setHomeData(HomeCareHomeScreen);
    }
    if (isHomeScreenConversations) {
      setHomeData(HomeScreenScreenConversations);
    }
  }, []);


  return (
    <React.Fragment>
      <Header {...props} isConversationPath={false}>
        <Container
          style={{
            paddingBottom: "50px",
            backgroundColor: "#FAF7DC",
            containerPadding: "50px",
            containerBackground: "#faf7dc",
            containerMargin: "163px",
          }}
          notSticky={true}
        >
          {!props.userData.isLoading &&
            homecareData &&
            homecareData.pageContent.planks.map((homecare, idx) => {
              const isCompleted = isConversationsCompletedObj.map(obj => obj.value).includes(homecare.sectionCompletionNumber);

              return (
                <React.Fragment key={idx}>
                  {idx === 0 && <div style={{ marginTop: "-20px" }}></div>}
                  {idx === 2 && isHomecareHomeScreen && (
                    <Ellipse isAllActive color={"#9CBF85"} />
                  )}
                  <Plank
                    key={idx}
                    planks={isPlankFinished}
                    icon={homecare.icon}
                    contentOne={homecare.contentOne}
                    subHeading={homecare.subHeading}
                    contentTwo={homecare.contentTwo}
                    title={homecare.title}
                    buttonLink={homecare.buttonLink}
                    {...(isCompleted ? { bulletList: homecare.bulletList } : {})}
                    {...props}
                    isCompleted={isCompleted}
                  />
                </React.Fragment>
              );
            })}
        </Container>
      </Header>
      <Footer active={isHomecareHomeScreen ? "HomecareHomeScreen" : "home"} />
    </React.Fragment>
  );
}

export default HomeScreen;
